<template>
    <div>
        <CModal
            :show.sync="openModal"
            :no-close-on-backdrop="true"
            :centered="false"
            title="Modal title 2"
            size="lg"
            color="success"
            >
            <div>
              <CForm @submit.prevent="editarnuevoGrupo($event)">
                <CInput
                    label="Nombre del Grupo"
                    placeholder="Nombre del Grupo"
                    autocomplete="off"
                    minlength="3"
                    required
                    v-model="name_group"
                    />
                    <hr>
                    <div class="w-full mt-4 p-10">
                        <CButton
                            color="dark"
                            variant="outline"
                            square
                            size="sm"
                            @click="addMore()">
                            Agregar Campo
                        </CButton>
                            <div v-for="(course, index) in list_fields" :key="index">
                                <CRow>
                                    <CCol col="6">
                                        <CInput
                                            label="Nombre del campo"
                                            placeholder="Nombre del campo"
                                            autocomplete="off"
                                            minlength="3"
                                            required
                                            v-model="course.field"
                                            :disabled="index == 0"
                                            @keyup="NumTextV2"
                                        />
                                    </CCol>
                                    <CCol col="4">
                                        <CSelect
                                            label="Tipo de Campo"
                                            required
                                            :value.sync="course.typeField"
                                            :options="typeFields"
                                            :disabled="index == 0"
                                        ></CSelect>
                                    </CCol>
                                    <CCol col="2" class="d-flex align-items-center justify-content-center mt-3">
                                        <CButton
                                        color="danger"
                                        variant="outline"
                                        square
                                        size="sm"
                                        @click="remove(index)"
                                        v-show="index != 0">
                                        -
                                    </CButton>
                                    </CCol>
                                </CRow>
                            </div>
                        </div>
                    <hr>
                    <CButton size="sm" color="danger" type="submit">
                        Editar Grupo
                    </CButton>
                    
                </CForm>
            </div>
            <template #header>
                <h6 class="modal-title">Editar grupo ({{name_group}}) de emails</h6>
                <CButtonClose @click="closeModal('Close_Edit')" class="text-white"/>
            </template>
            <template #footer>
                <CButton @click="closeModal('Close_Edit')" color="danger">Cerrar</CButton>
                <CButton @click="closeModal('Close_Edit')" color="success" style="display:none;">Accept</CButton>
            </template>
        </CModal>
  <div>
          <CToaster :autohide="5000" class="d-inline-block">
             <template v-for="toast in mostrarNotificacion">
                <CToast
                  :key="'toast'+toast"
                  :show="true"
                  color="success"
                  header="Notificacion Exitosa"
                >
                  {{mensaje_success}}
                </CToast>
              </template>   
              <template v-for="toast1 in mostrarNotificacionAlerta">
                <CToast
                  :key="'toast1'+toast1"
                  :show="true"
                  color="warning"
                  header="Notificacion Alerta"
                >
                  {{mensaje_warning}}
                </CToast>
              </template>
              <template v-for="toast2 in mostrarNotificacionError">
                <CToast
                  :key="'toast2'+toast2"
                  :show="true"
                  color="danger"
                  header="Notificacion Alerta"
                >
                  Error Comunicarse Con Soporte
                </CToast>
              </template>  
          </CToaster>
          <input type="hidden" :value="reloadModal">
  </div>
    </div> 
</template>

<script>
export default {
  name: 'EditarGrupo',
  components: {
  },
  props:{
    openModal: Boolean,
    formEdit:Object
  },
  data () {
    return {
        modal_crear_grupo:true,
        name_group:'',
        list_fields: [],
        typeFields:[
            { value: `string`,label: `Texto`},
            { value: `number`,label: `Numerico`},
            { value: `email`,label: `Email`},
            { value: `decimal`,label: `Decimal 0.00` }
        ],
        mostrarNotificacion: 0,
        mostrarNotificacionAlerta: 0,
        mostrarNotificacionError:0,
        mensaje_warning:'Debe Completar Los Datos Vacio',
        mensaje_success:'Grupo Editado Correctamente.',
    }
  },
  computed:{
    reloadModal(){
      this.name_group = this.formEdit.nombre_grupo;
      if (this.formEdit.campos_personalizados != undefined) {
        this.list_fields = JSON.parse(this.formEdit.campos_personalizados);
      }
      
      return "";
    }
  },
  methods: {
    closeModal(type) {
      this.$emit("close-modal",type);
    },
    addMore() {
      this.list_fields.push({
            field: "",
            typeField: "string"
        });
    },
    remove(index) {
      this.list_fields.splice(index, 1);
    },
    editarnuevoGrupo(evt){
      evt = (evt) ? evt : window.event;
      evt.target.disabled = true;
      this.$store.dispatch('auth/loading_http','visible');
      this.$http.post(this.$httpUrlMail+"/mail/grupos/update",{
        id:this.formEdit.id_grupo,
        nombre:this.name_group,
        campos:this.list_fields
      },{ withCredentials: false }).then(response =>{
        let data = response.data;
        if (data == "OK") {
            this.mensaje_success=`Grupo ${this.name_group} Editado Correctamente.`;
            evt.target.disabled = false;
            this.nombre_grupo = '';
            this.mostrarNotificacion ++;
            this.closeModal('Close_Edit_Success');
        }else{
              let mensajeError = '';
              let dataError = response.data;
              for (const key in dataError) {
                if (Object.hasOwnProperty.call(dataError, key)) {
                  const element = dataError[key];
                  //mensajeError += `${element.mensaje} \n`
                  for (const iterator of element) {
                    mensajeError += `${iterator} \n`
                  }
                }
              }
              this.mensaje_warning = mensajeError
              this.mostrarNotificacionAlerta += 1;
              evt.target.disabled = false;
        }
      }).catch(error=>{this.mostrarNotificacionError ++;evt.target.disabled = false;})
      .finally(() =>this.$store.dispatch('auth/loading_http','hidden'))
    },
    NumTextV2(event) {
      let string = event.target.value;
      //solo letras y numeros
      var out = "";
      //Se añaden las letras validas
      //áéíóúÁÉÍÓÚ
      var filtro =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890_"; //Caracteres validos

      for (var i = 0; i < string.length; i++)
        if (filtro.indexOf(string.charAt(i)) != -1) out += string.charAt(i);
    
      event.target.value = out;
    },
  },
  mounted(){
  },
}
</script>

