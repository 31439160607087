<template>
<CContainer>
  <CRow>
      <CCol col="12">
        <CCard>
          <CCardHeader>
            <h3>Grupos Mailing</h3>
          </CCardHeader>
        </CCard>
      </CCol>
   </CRow>
   <CRow>
      <CCol col="6">
         <CCard>
          <CCardHeader>
            <h5>Crear Grupo Mailing</h5>
          </CCardHeader>
          <CCardBody>
            <CButton size="sm" color="danger" @click="validateMaximoGrupos()">
                    Crear Grupo
            </CButton>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol col="6">
        <CCard>
          <CCardHeader>
            <h5>Reporte Grafico</h5>
          </CCardHeader>
          <CCardBody>
                
                <CCardBody>
                    <CChartPie
                      :datasets="defaultDatasets"
                      :labels="labelsChart"
                    />
                </CCardBody>
          </CCardBody>
        </CCard>
      </CCol>
   </CRow>
     <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
           <CRow class="justify-content-center">
              <CCol col="6">
                <h5 class="text-center">Cantidad de grupos creados: {{items.length}}</h5>
              </CCol>
           </CRow>
        </CCardHeader>
        <CCardBody>
          <CDataTable
            hover
            striped
            :items="items"
            :fields="fields"
            :items-per-page="10"
            :pagination="{ doubleArrows: false, align: 'center'}"
            column-filter
            @page-change="pageChange"
          >
          <template #cantidad="{item, index}">
              <td>
             {{$formatterNumeroTipoCreditos(obtenerCantidadIntegrantes(item.detalle))}}
             </td>
           </template>
          <template #created_at="{item}">
              <td>
                 {{formatoFecha(item.created_at)}}
              </td>
          </template>
          <template #acciones="{item}">
            <td>
              <CButton size="sm" color="success" @click="formEditGroupModal(item)">
                    Editar Grupo
              </CButton>
            </td>
            <td>
              <router-link :to="'/mailing/grupos/integrantes/'+item.id_grupo">
                  <CButton size="sm" color="info">
                    Ver Emails
                  </CButton>
              </router-link>
            </td>
            <td>
              <CButton size="sm" color="danger" @click="abrirmodalEliminarGrupo(item)">
                    Eliminar Grupo
              </CButton>
            </td>
          </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
  <div>
          <CToaster :autohide="5000" class="d-inline-block">
             <template v-for="toast in mostrarNotificacion">
                <CToast
                  :key="'toast'+toast"
                  :show="true"
                  color="success"
                  header="Notificacion Exitosa"
                >
                  {{mensaje_success}}
                </CToast>
              </template>   
              <template v-for="toast1 in mostrarNotificacionAlerta">
                <CToast
                  :key="'toast1'+toast1"
                  :show="true"
                  color="warning"
                  header="Notificacion Alerta"
                >
                  {{mensaje_warning}}
                </CToast>
              </template>
              <template v-for="toast2 in mostrarNotificacionError">
                <CToast
                  :key="'toast2'+toast2"
                  :show="true"
                  color="danger"
                  header="Notificacion Alerta"
                >
                  Error Comunicarse Con Soporte
                </CToast>
              </template>  
          </CToaster>
          <CModal
            :show.sync="modal_eliminar_grupo"
            :no-close-on-backdrop="true"
            :centered="true"
            title="Modal title 2"
            size="sm"
            color="dark"
          >
            <CRow>
              <CCol col="12">
                Usted esta Apunto de Eliminar el Grupo : <strong>{{form_eliminar_grupo.nombre}}</strong>
              </CCol>
            </CRow>
            <template #header>
              <h6 class="modal-title">Eliminar Grupo</h6>
              <CButtonClose @click="modal_eliminar_grupo = false" class="text-white"/>
            </template>
            <template #footer>
              <CButton @click="modal_eliminar_grupo = false" color="danger">Cancel</CButton>
              <CButton  color="success" @click="eliminarGrupoPorId()">Aceptar</CButton>
            </template>
          </CModal>
          <CrearGrupo :openModal="modal_crear_grupo" @close-modal="closeModalGroup"/>
          <EditarGrupo :openModal="modal_editar_grupo" :formEdit="formEditGroup" @close-modal="closeModalGroup"/>
  </div>
</CContainer>

</template>


<script>
import { CChartPie } from '@coreui/vue-chartjs'
import CrearGrupo from './CrearGrupo.vue'
import EditarGrupo from './EditarGrupo.vue'
export default {
  name: 'Grupos',
  components: { CChartPie, CrearGrupo,EditarGrupo },
  data () {
    return {
      items: [],
      fields: [
        { key: 'nombre_grupo', label: 'Nombre', _classes: 'font-weight-bold' },
        { key: 'cantidad',label:'Cantidad de Integrantes' },
        { key: 'created_at',label:'Fecha Creado' },
        { key: 'acciones',label:'',
          _style: { width: '1%' },
          sorter: false,
          filter: false 
        }
      ],
      activePage: 1,
      collapseDuration: 0,
      nombre_grupo:'',
      mostrarNotificacion: 0,
      mostrarNotificacionAlerta: 0,
      mostrarNotificacionError:0,
      mensaje_warning:'Debe Completar Los Datos Vacio',
      mensaje_success:'Grupo Creado Correctamente.',
      modal_eliminar_grupo:false,
      form_eliminar_grupo:{
        nombre:'',
        id:'',
      },
      labelsChart:[],
      dataChart:[],
      modal_crear_grupo:false,
      modal_editar_grupo:false,
      formEditGroup:{},
      parametros_configuracion:{
        maximo_grupos:5
      }
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    }
  },
  methods: {
    getBadge (status) {
      switch (status) {
        case 1: return 'success'
        case 0: return 'danger'
      }
    },
    formEditGroupModal (item) {
      this.formEditGroup = item;
      this.modal_editar_grupo = true;
    },
    closeModalGroup (type) {
      switch (type) {
        case 'Close_Create':
          this.modal_crear_grupo = false;
          break;
        case 'Close_Edit':
          this.modal_editar_grupo = false;
          break;
        case 'Close_Create_Success':
          this.modal_crear_grupo = false;
          this.getGrupos();
          break;
        case 'Close_Edit_Success':
          this.modal_editar_grupo = false;
          this.getGrupos();
          break;
      
        default:
          break;
      }
      
    },
    rowClicked (item, index) {
      this.$router.push({path: `users/${index + 1}`})
    },
    pageChange (val) {
      this.$router.push({ query: { page: val }})
    },
    getGrupos(){
        this.$store.dispatch('auth/loading_http','visible');
        this.$http.get(this.$httpUrlMail+"/mail/grupos",{ withCredentials: false }).then(response => {
             this.items = response.data;
             this.items.map((item,id) => {  
                if (id%2 == 0) {
                  item['_classes'] = 'table-now'; 
                }
                return item;
              })
             //this.items = this.items.map((item, id) => { return {...item, id}})

             this.labelsChart = this.items.map((item) => { return item.nombre_grupo})
             this.dataChart = this.items.map((item) => { return this.obtenerCantidadIntegrantes(item.detalle)  })
        }).catch(()=>{
             
        }).finally(() =>this.$store.dispatch('auth/loading_http','hidden'));
    },
    toggleDetails (item) {
      this.$set(this.items[item.id], '_toggled', !item._toggled)
      this.collapseDuration = 300
      this.$nextTick(() => { this.collapseDuration = 0})
    },
    eliminarGrupoPorId(){
      this.modal_eliminar_grupo = false
      this.$store.dispatch('auth/loading_http','visible');
      this.$http.delete(this.$httpUrlMail+'/mail/grupos/'+this.form_eliminar_grupo.id,{ withCredentials: false }).then(response =>{
        let data = response.data;
        if (data == "OK") {
            let nombre = this.form_eliminar_grupo.nombre;
            this.form_eliminar_grupo.id = ''
            this.form_eliminar_grupo.nombre = ''
            this.mostrarNotificacion ++;
            this.getGrupos();
            this.mensaje_success=`Grupo ${nombre} Eliminado Correctamente.`,
            setTimeout(() => {this.mensaje_success='Grupo Creado Correctamente.'},6000)
        }else{
              let mensajeError = '';
              let dataError = response.data;
              for (const key in dataError) {
                if (Object.hasOwnProperty.call(dataError, key)) {
                  const element = dataError[key];
                  //mensajeError += `${element.mensaje} \n`
                  for (const iterator of element) {
                    mensajeError += `${iterator} \n`
                  }
                }
              }
              this.mensaje_warning = mensajeError
              this.mostrarNotificacionAlerta += 1;
        }
      }).catch(error=>{this.mostrarNotificacionError ++;console.log(error);}).finally(() =>this.$store.dispatch('auth/loading_http','hidden'))
    },
    formatoFecha(fecha){
      let fechaformato = fecha.split('.')[0];
      return fechaformato.replace('T',' ');
    },
    abrirmodalEliminarGrupo(item){
      this.modal_eliminar_grupo = true;
      this.form_eliminar_grupo.nombre = item.nombre_grupo;
      this.form_eliminar_grupo.id = item.id_grupo;
    },
    obtenerCantidadIntegrantes(detalleJson){
      let detalle;
       try {
          detalle = JSON.parse(detalleJson);
          return detalle.max;
       } catch (error) {
        return 0;
       }
    },
    getConfigUser(){
        this.$store.dispatch("auth/loading_http", "visible");
        this.$http
          .get(this.$httpUrlMail+"/mail/config/user",{withCredentials:false})
          .then((response) => {
             this.parametros_configuracion = response.data;
          })
          .catch(() => {
            this.mostrarNotificacionError++;
          })
          .finally(() => this.$store.dispatch("auth/loading_http", "hidden"));
    },
    validateMaximoGrupos(){
      if(this.items.length >= this.parametros_configuracion.maximo_grupos){
        this.mensaje_warning = `Has alcanzado el límite de grupos creados. Para crear uno nuevo, por favor elimina un grupo existente`;
        this.mostrarNotificacionAlerta++;
      }else{
        this.modal_crear_grupo = true;
      }
    }
  },
  mounted(){
    setTimeout(() => {
      this.getGrupos();
      this.getConfigUser();
    }, 100);
  },
  computed: {
    defaultDatasets () {
      return [
        {
          backgroundColor: [
            '#41B883',
            '#E46651',
            '#00D8FF',
            '#DD1B16'
          ],
          data: this.dataChart
        }
      ]
    }
  }
}
</script>
